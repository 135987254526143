import { ServiceData } from "../../components/ServiceCard";

const services: ServiceData[] = [
    {
        title: "Banery",
        description:
            "Banery sprawdzają się tam, gdzie trzeba szybkiej, niedrogiej informacji. Wiszą głównie na płotach, balkonach, ścianach, trybunach sportowych, tablicach reklamowych.",
        imageCode: "1",
    },
    {
        title: "Druk na folii samoprzylepnej",
        description:
            "Wydruki na foliach samoprzylepnych stosujemy do wyklejania na najróżniejszych podłożach takich jak tablice, witryny, pojazdy, ściany, drzwi itd. Dla lepszej ochrony pokrywamy wydruki laminatem foliowym.",
        imageCode: "2",
    },
    {
        title: "Druk na papierze billboardowym",
        description:
            "Ten najtańszy nośnik reklamy wyklejamy głównie na tablicach reklamowych, nadaje się również do dekoracji we wnętrzach.",
        imageCode: "3",
    },
    {
        title: "Druk plakatów na papierze ozdobnym",
        description:
            "Drukujemy cyfrowo plakaty już od jednej sztuki w dowolnym formacie zachowując wysoką jakość.",
        imageCode: "4",
    },
    {
        title: "Fototapety",
        description:
            "Dowolny wzór tapety na nośniku flizelinowym daje możliwość niepowtarzalnej aranżacji wnętrza. Nasze tapety zdobią ściany w obiektach usługowych, domach, galeriach.",
        imageCode: "5",
    },
    {
        title: "Naklejki, etykiety, wlepki",
        description:
            "Wycinamy dowolne kształty naklejek bez konieczności wykonania kosztowych wykrojników. Tak powstają naklejki okolicznościowe, dekoracje, etykiety.",
        imageCode: "6",
    },
    {
        title: "Naklejki podłogowe",
        description:
            "Po naszych naklejkach bezpiecznie biegają zawodnicy w halach sportowych oraz klienci w supermarketach.",
        imageCode: "7",
    },
];

function getPrintData() {
    return services;
}

export default getPrintData;
