import * as React from "react";
import Layout from "../components/Layout";
import useColor from "../context/ColorContext";
import ServicesPanel from "../components/ServicesPanel";
import getPrintData from "../utils/functions/getPrintData";

const Print = () => {
    const { setColor } = useColor();
    const services = getPrintData();

    setColor?.("blue");
    return (
        <>
            <Layout>
                <ServicesPanel
                    description="Wydruki w każdej wielkości już od jednej sztuki na dowolnym dostępnym podłożu."
                    heading={"Druk cyfrowy i offsetowy"}
                    services={services}
                    category={"druk"}
                />
            </Layout>
        </>
    );
};

export default Print;
